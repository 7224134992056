import * as React from "react";
import { withTrans } from "../../i18n/withTrans";
import { Link } from "gatsby";

// style
import "../../style/components/header.scss";

//images
import foursourceLogoBlack from "../../images/fs-black-logo.svg";
import foursourceLogoWhite from "../../images/fs-white-logo.svg";
import ispoLogoWhite from "../../images/ispo-white-logo.svg";
import ispoLogoBlack from "../../images/ispo-black-logo.svg";


const SCROLL_MENU = 55;
const MENU_MOBILE = 992;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      menuBackground: false,
      menuMobile: window.innerWidth <= MENU_MOBILE ? true : false,
    };
    this.t = props.t;
  }

  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll, true);
    window.addEventListener("resize", this.handleResize, true);
  };

  handleScroll = () => {
    let show = window.scrollY > SCROLL_MENU ? true : false;
    this.setState({
      menuBackground: show,
    });
  };

  handleResize = () => {
    let show = window.innerWidth <= MENU_MOBILE ? true : false;
    this.setState({
      menuMobile: show,
    });
  };

  menuMobile = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  render() {
    return (
      <div id="menuContent">
        <div
          id="headerDiv"
          className={
            "paddingSides paddingHeader w-100 position-fixed d-flex align-items-center justify-content-center " +
            (this.props.whiteMenu || this.state.menuBackground || this.state.menuMobile ? "background" : "")
          }
        >
          <nav className="headerNav d-inline-flex align-items-center w-100">
            <Link to="/" activeStyle={{ fontWeight: "bold" }}>
              <img
                className="headerLogo"
                src={
                  this.props.darkText || this.props.whiteMenu || this.state.menuBackground || this.state.menuMobile
                    ? foursourceLogoBlack
                    : foursourceLogoWhite
                }
                alt="Foursource logo"
              />
            </Link>
          </nav>
          <nav className="headerNav d-inline-flex justify-content-end align-items-center w-100">
            <Link to="https://innovation.ispo.com/pages/ueber?lang=en" activeStyle={{ fontWeight: "bold" }}>
              <img
                className="headerLogo"
                src={
                  this.props.darkText || this.props.whiteMenu || this.state.menuBackground || this.state.menuMobile
                    ? ispoLogoBlack
                    : ispoLogoWhite
                }
                alt="Foursource logo"
              />
            </Link>
          </nav>
        </div>
      </div>
    );
  }
}

export default withTrans(Header);
