import * as React from "react";
import CookieConsent from "react-cookie-consent";
import Parser from "html-react-parser";

import { withTrans } from "../../i18n/withTrans";

import "../../style/components/cookiesMessage.scss";

class CookiesMessage extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;
  }

  // 180 days (6 months) - privacy cookies duration
  render() {
    return (
      <CookieConsent
        onAccept={() => {
          if (!process.env.GATSBY_CHINA) {
            window.gtag('consent', 'update', {
              ad_storage: 'granted',
              analytics_storage: 'granted'
            });
          }
        }}
        debug={process.env.DEBUG}
        location="bottom"
        buttonText={this.t("cookies.accept")}
        cookieName="foursourceCookie"
        buttonId="acceptButton"
        expires={180}
      >
        <span className="font16 fontWeight300">{Parser(this.t("cookies.message"))}</span>
      </CookieConsent>
    );
  }
}

export default withTrans(CookiesMessage);
