import React from 'react';
import { withTrans } from '../../i18n/withTrans';
import { Helmet } from 'react-helmet';

// images
import Favicon from '../../images/favicon/fs-icon.png';
import Favicon32 from '../../images/favicon/fs-icon-32x32.png';
import Favicon144 from '../../images/favicon/fs-icon-144x144.png';
import Favicon180 from '../../images/favicon/fs-icon-180x180.png';
import Favicon192 from '../../images/favicon/fs-icon-192x192.png';
import Favicon270 from '../../images/favicon/fs-icon-270x270.png';

const THEME_COLOR = '#48ac67';

class Head extends React.Component {
  render() {
    const { info } = this.props;

    return (
      <>
        <Helmet>
          <meta charset='UTF-8' />
          <meta
            name='viewport'
            content='width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=0'
          />
          <meta http-equiv='X-UA-Compatible' content='IE=edge,chrome=1' />
          <meta name='robots' content='index, follow' />
          <meta name='theme-color' content={THEME_COLOR} />
          <meta name='msapplication-navbutton-color' content={THEME_COLOR} />
          <meta name='apple-mobile-web-app-status-bar-style' content={THEME_COLOR} />

          <link rel='shortcut icon' type='image/png' href={Favicon}></link>
          <link rel='shortcut icon' href={Favicon192} type='image/png' sizes='192x192' />
          <link rel='apple-touch-icon' href={Favicon32} type='image/png' sizes='32x32' />
          <link rel='apple-touch-icon' href={Favicon144} type='image/png' sizes='144x144' />
          <link rel='apple-touch-icon' href={Favicon180} type='image/png' sizes='180x180' />
          <link rel='apple-touch-icon' href={Favicon192} type='image/png' sizes='192x192' />
          <link rel='apple-touch-icon' href={Favicon270} type='image/png' sizes='270x270' />

          <link rel='preconnect' href='https://fonts.googleapis.com' />
          <link rel='preconnect' href='https://fonts.gstatic.com' crossorigin />
          <link
            href='https://fonts.googleapis.com/css2?family=Fraunces:ital,opsz,wght@0,9..144,400;0,9..144,500;0,9..144,600;0,9..144,700;1,9..144,400;1,9..144,500;1,9..144,600;1,9..144,700&display=swap'
            rel='stylesheet'
          />

          <title>{info.title}</title>
          <meta name='description' content={info.description} />
          <link rel='canonical' href={info.url} />

          <meta property='og:locale' content='en_US' />
          <meta property='og:title' content={info.og.title} />
          <meta property='og:description' content={info.og.description} />
          <meta property='og:url' content={info.og.url} />
          <meta property='og:site_name' content={info.og.site_name} />
          <meta property='og:image' content={info.og.image.url} />
          <meta property='og:image:secure_url' content={info.og.image.url} />
          <meta property='og:image:width' content={info.og.image.width} />
          <meta property='og:image:height' content={info.og.image.height} />
          <meta property='og:image:alt' content={info.og.image.alt} />

          <meta name='twitter:card' content='summary_large_image' />
          <meta name='twitter:title' content={info.twitter.title} />
          <meta name='twitter:description' content={info.twitter.description} />
          <meta name='twitter:image' content={info.twitter.img} />
          <meta name='twitter:site' content={info.twitter.creator} />
          <meta name='twitter:creator' content={info.twitter.creator} />
        </Helmet>
      </>
    );
  }
}

export default withTrans(Head);
