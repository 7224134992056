// Base
import styled from 'styled-components';

// MUI Components
import Link from '@mui/material/Link';
import SvgIcon from '@mui/material/SvgIcon';

export const LogoButton = styled(Link)`
  color: ${(props) => props.sx.color || 'inherit'};
  &:hover {
    color: inherit;
  }
`;

export const LogoSvg = styled(SvgIcon)`
  width: auto;
  height: auto;
`;
