import React from 'react';
import { withTrans } from '../i18n/withTrans';
import '../style/general.min.scss';

// images
import SEOimg from '../images/seoFoursource.png';

// components
import Loading from './_global/loading';
import TexHeader from './textrends/header';
import Seo from './_global/seo';
import FooterV2 from './_global/footerV2';
import CookiesMessage from './_global/cookiesMessage';
import Scripts from './_global/scripts';

// components from new repo
import Header from './Header';
import { ThemeProvider } from '@mui/material';
import { theme } from '../theme';

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.state = { isLoading: true };
  }

  componentDidMount() {
    setTimeout(() => this.setState({ isLoading: false }), 1500);
  }

  getDefaultSEO = (pageName) => {
    return {
      title: this.t(pageName + '.seo.title'),
      description: this.t(pageName + '.seo.description'),
      canonical: this.t(pageName + '.seo.link'),
      og: {
        title: this.t(pageName + '.seo.title'),
        description: this.t(pageName + '.seo.description'),
        url: this.t(pageName + '.seo.link'),
        site_name: 'FOURSOURCE',
        image: {
          url: SEOimg,
          secure_url: SEOimg,
          width: '1280',
          height: '671',
          alt: 'Loading...',
        },
      },
      twitter: {
        title: this.t(pageName + '.seo.title'),
        description: this.t(pageName + '.seo.description'),
        image: SEOimg,
        creator: '@Foursource',
      },
    };
  };

  render() {
    const { children, pageName, whiteMenu, darkText, textrends, headerWhite } =
      this.props;

    return (
      <>
        <ThemeProvider theme={theme}>
          <Seo info={this.getDefaultSEO(pageName)}></Seo>
          <Scripts />
          {this.state.isLoading ? (
            <Loading />
          ) : (
            <div id='page-content'>
              {textrends ? (
                <TexHeader
                  whiteMenu={whiteMenu}
                  darkText={darkText}
                ></TexHeader>
              ) : (
                <Header headerWhite={headerWhite} />
              )}
              <main className='pa-0'>{children}</main>
              <CookiesMessage />
              <FooterV2 />
            </div>
          )}
        </ThemeProvider>
      </>
    );
  }
}

export default withTrans(Layout);
