// Base
import styled from 'styled-components';

// Components
import { AppBar, Button } from '@mui/material';

export const WhiteButton = styled(Button)`
  color: #ffffff;
  border-color: #ffffff;
  &:hover {
    color: #ffffff;
  }
`;
export const Header = styled(AppBar)`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.header-white {
    background: #ffffff !important;
    color: #353f58 !important;
    border-bottom: 1px solid #e6e8ed !important;
    box-shadow: none;
    & .MuiButton-outlined {
      color: #32a261 !important;
      border-color: #32a261 !important;
      &:hover {
        color: #ffffff !important;
        border-color: #32a261 !important;
      }
    }
  }
`;
