import * as React from 'react'
import { globalHistory } from '@reach/router'

// Styles
import '../../style/components/footerVTwo.scss'

// Images
import foursourceLogoGrey from '../../images/logos/foursource-logo-grey.svg'
import portugal2020 from '../../images/portugal2020.jpg'
import ispoLogoBlack from '../../images/logos/ispo-textrends-logo-grey.svg'
import linkedinLogoGrey from '../../images/social-icons/linkedin-icon-grey.svg'
import instagramLogoGrey from '../../images/social-icons/instagram-icon-grey.svg'
import facebookLogoGrey from '../../images/social-icons/facebook-icon-grey.svg'

// Components

const FooterV2 = () => {
  const currentYear = new Date().getFullYear()
  return (
    <div className={'footerContainer'}>
      <div className='footer'>
        <div className='d-flex flex-row flex-wrap justify-content-center'>
          <div className='footerLogos'>
            <div className='foursourceLogo'>
              <a href='/'>
                <img src={foursourceLogoGrey} height={32} alt={'Foursource'} />
              </a>
            </div>
            <div className='d-flex flex-row mt-56 mt-sm-16'>
              <div className='ispoLogo'>
                <a href='/ispo-textrends-award/'>
                  <img src={ispoLogoBlack} height={52} alt={'Ispo Textrends'} />
                </a>
              </div>
            </div>
            <p className='font-size-12 pt-12 mb-0'>
              {
                'FOURSOURCE Group GmbH is the license partner of ISPO Textrends Award, operating under a license agreement granted by Messe München GmbH.'
              }
            </p>
          </div>
          <div className='d-flex flex-wrap footerColumns flex-wrap '>
            <div className='footerColumn mr-sm-12'>
              <b>{'Company'}</b>
              <div className='footerLink mt-24'>
                <a className='text-decoration-none font-size-14' href='/about-us/'>
                  {'About us'}
                </a>
              </div>
              <div className='footerLink mt-12'>
                <a className='text-decoration-none font-size-14' href='/branches/portugal/'>
                  {'Foursource Portugal'}
                </a>
              </div>
              <div className='footerLink mt-12'>
                <a className='text-decoration-none font-size-14' href='/branches/turkey/'>
                  {'Foursource Turkey'}
                </a>
              </div>
              <div className='footerLink mt-24' hidden>
                <a className='text-decoration-none font-size-14' href='/career/'>
                  {"We're Hiring"}
                </a>
              </div>
              <div className='footerLink mt-12'>
                <a className='text-decoration-none font-size-14' href='/contact-us/'>
                  {'Contact us'}
                </a>
              </div>
            </div>
            <div className='footerColumn'>
              <b>{'Product'}</b>
              <div className='footerLink mt-24'>
                <a className='text-decoration-none font-size-14' href='/explore-network/'>
                  {'Explore Network'}
                </a>
              </div>
              <div className='footerLink mt-12'>
                <a className='text-decoration-none font-size-14' href='/pricing/'>
                  {'Pricing'}
                </a>
              </div>
              <div className='footerLink mt-12' hidden>
                <a className='text-decoration-none font-size-14' href='/sustainability/'>
                  {'Sustainability'}
                </a>
              </div>
              <div className='footerLink mt-12'>
                <a className='text-decoration-none font-size-14' href='/ispo-textrends-award/'>
                  {'ISPO Textrends Award'}
                </a>
              </div>
              <div className='footerLink mt-12'>
                <a className='text-decoration-none font-size-14' href='/references/'>
                  {'Members'}
                </a>
              </div>
              <div className='footerLink mt-12'>
                <a className='text-decoration-none font-size-14' href='https://public.foursource.com/manufacturers'>
                  {'Network'}
                </a>
              </div>
            </div>
            <div className='footerColumn'>
              <b>{'Business Services'}</b>
              <div className='footerLink mt-24'>
                <a href='/for-suppliers/' className='text-decoration-none font-size-14'>
                  {'For Suppliers'}
                </a>
              </div>
              <div className='footerLink mt-12'>
                <a className='text-decoration-none font-size-14' href='/case-study/'>
                  {'Case Studies'}
                </a>
              </div>
              <div className='mt-12' hidden>
                <a className=' text-decoration-none font-size-14' href='/how-to-get-started/'>
                  {'How to get started'}
                </a>
              </div>
            </div>
          </div>
        </div>
        {(globalHistory.location.pathname === '/branches/portugal/' ||
          globalHistory.location.pathname === '/norte-2020/') && (
          <a href='/norte-2020/'>
            <img className='portugal2020' alt='Portugal 2020' src={portugal2020} placeholder='blurred' />
          </a>
        )}
        <div className='rightsAndTerms mt-64 mt-sm-32'>
          <hr></hr>
          <div className='d-flex flex-lg-row flex-column text-grey-8 flex-wrap justify-content-between'>
            <div className='font-size-12 mt-20 text-grey-8 m-auto m-md-auto m-lg-0'>
              <p>
                © {currentYear} {'FOURSOURCE Group GmbH. All rights reserved.'}
              </p>
            </div>
            <div className='social mt-16 mx-auto'>
              <a
                className='footerSocialIcon'
                href='https://www.linkedin.com/company/foursource/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={linkedinLogoGrey} height={24} alt={'Linkedin'} />
              </a>
              <a
                className='footerSocialIcon'
                href='https://www.instagram.com/foursource/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={instagramLogoGrey} height={24} alt={'Instagram'} />
              </a>
              <a
                className='footerSocialIcon'
                href='https://www.facebook.com/foursource'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={facebookLogoGrey} height={24} alt={'Facebook'} />
              </a>
            </div>
            <div className='mt-20 m-auto m-md-auto m-lg-0'>
              <a className='text-grey-8 text-decoration-none font-size-12 mr-16' href='/code-of-conduct/'>
                {'Code of Conduct'}
              </a>
              <a className='text-grey-8 text-decoration-none font-size-12 e mr-16' href='/imprint/'>
                {'Imprint'}
              </a>
              <a className='text-grey-8 text-decoration-none font-size-12 mr-16' href='/terms-conditions/'>
                {'Terms And Conditions'}
              </a>
              <a className='text-grey-8 text-decoration-none font-size-12' href='/privacy-policy/'>
                {'Privacy Policy'}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterV2
