// Base
import React from 'react';

// Assets
import FoursourceLogo from '../../images/icon/foursource-logo.inline.svg';
import FoursourceLogoMini from '../../images/icon/foursource-logo-mini.inline.svg';

// Styles
import * as S from './Logo.styles';

const Logo = (props) => {
  const size = props.size;
  return (
    <S.LogoButton color='inherit' {...props}>
      <S.LogoSvg component={size === 'mini' ? FoursourceLogoMini : FoursourceLogo} inheritViewBox></S.LogoSvg>
    </S.LogoButton>
  );
};

export default Logo;
