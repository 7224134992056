import { useEffect } from 'react'

function Scripts() {
  useEffect(() => {
    if (process.env.GATSBY_INSTANA === 'true') {
      // Instana
      const script = document.createElement('script')
      let instanaKey = process.env.GATSBY_CHINA === 'true' ? '05Ux9dInTsKfyvyU6p7whQ' : 'hbFmqoV2RJy_nmj4o4VCXg'
      let scriptText = document.createTextNode(
        `/* INSTANA */
        (function(c,e,f,k,g,h,b,a,d){c[g]||(c[g]=h,b=c[h]=function(){
        b.q.push(arguments)},b.q=[],b.l=1*new Date,a=e.createElement(f),a.async=1,
        a.src=k,a.setAttribute("crossorigin", "anonymous"),d=e.getElementsByTagName(f)[0],
        d.parentNode.insertBefore(a,d))})(window,document,"script",
        "https://eum.instana.io/eum.min.js","InstanaEumObject","ineum");
        ineum('reportingUrl', 'https://eum-blue-saas.instana.io');
        ineum('key','` +
          instanaKey +
          `');`
      )
      script.appendChild(scriptText)
      document.head.appendChild(script)
    }
    if (process.env.GATSBY_CHINA === 'false') {
      // GTM (Google Tag Manager)
      const headGTM = document.createElement('script')
      headGTM.appendChild(
        document.createTextNode(`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
         new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
         j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
         'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
         })(window,document,'script','dataLayer','GTM-TRDZJ85')`)
      )

      // Append Scripts
      document.head.appendChild(headGTM)
    }
  }, [])

  return null
}

export default Scripts
