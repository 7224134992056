// Base
import * as React from 'react';

// Components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Logo from '../Logo';

// Icons
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';

// Styles
import * as S from './Header.styles';

// Variables and Constants
const navMenu = [
  {
    id: 1,
    text: 'Explore Network',
    url: '/explore-network/',
  },
  {
    id: 2,
    text: 'For Suppliers',
    url: '/for-suppliers/',
  },
  {
    id: 3,
    text: 'Case Studies',
    url: '/case-study/',
  },
];

const Header = (props) => {
  const [toggleDrawer, setToggleDrawer] = React.useState(false);

  const handleDrawerToggle = () => {
    setToggleDrawer(!toggleDrawer);
  };

  const ChangeOnScroll = (props) => {
    const { children, window } = props;

    const trigger = useScrollTrigger({
      disableHysteresis: true,
      target: window ? window() : undefined,
      threshold: 0,
    });

    return React.cloneElement(children, {
      style: {
        backgroundColor: toggleDrawer || trigger ? '#F9FAFC' : 'transparent',
        color: toggleDrawer || trigger ? '#353F58' : '#FFFFFF',
        borderBottom: toggleDrawer || trigger ? '1px solid #E6E8Ed' : 'none',
        boxShadow: 'none',
      },
      sx: {
        '& .MuiButton-outlined': {
          color: trigger ? '#32A261' : '',
          borderColor: trigger ? '#32A261' : '',
          '&:hover': {
            borderColor: trigger ? '#32A261' : '',
          },
        },
      },
    });
  };
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <List disablePadding>
        {navMenu.map(({ id, text, url }) => (
          <ListItem key={id} disablePadding>
            <ListItemButton
              divider
              href={url}
              sx={{ textAlign: 'center', py: '20px' }}
            >
              <ListItemText primary={text} sx={{ fontWeight: 700 }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <List>
        <ListItem>
          <Button
            fullWidth
            variant='contained'
            href={`${
              process.env.GATSBY_CHINA === 'true'
                ? process.env.APP_CHINA_URL
                : process.env.APP_URL
            }/signup`}
            rel='noopener'
          >
            Join now
          </Button>
        </ListItem>
        <ListItem>
          <Button
            fullWidth
            variant='outlined'
            href={`${
              process.env.GATSBY_CHINA === 'true'
                ? process.env.APP_CHINA_URL
                : process.env.APP_URL
            }/auth`}
            rel='noopener'
          >
            Login
          </Button>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <ChangeOnScroll {...props}>
        <S.Header
          position='fixed'
          className={props.headerWhite ? 'header-white' : ''}
        >
          <Container maxWidth='xl' disableGutters>
            <Toolbar disableGutters>
              <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size='large'
                  color='inherit'
                  aria-label='open drawer'
                  edge='start'
                  onClick={handleDrawerToggle}
                >
                  {toggleDrawer ? <CloseIcon /> : <MenuIcon />}
                </IconButton>
              </Box>

              {/* Logo Mobile */}
              <Logo
                size='mini'
                href='/'
                sx={{
                  flexGrow: 1,
                  display: { xs: 'flex', sm: 'none', md: 'flex', lg: 'none' },
                }}
              />

              {/* Logo Desktop */}
              <Logo
                href='/'
                sx={{
                  flexGrow: { xs: 0, sm: 1 },
                  display: { xs: 'none', sm: 'flex', md: 'none', lg: 'flex' },
                }}
              />

              {/* Menu Desktop */}
              <Box
                sx={{
                  flexGrow: 1,
                  justifyContent: 'center',
                  display: { xs: 'none', md: 'flex' },
                }}
              >
                {navMenu.map(({ id, text, url }) => (
                  <Button
                    rel='noopener'
                    variant='button'
                    href={url}
                    key={id}
                    sx={{
                      my: 2,
                      ml: 1,
                      color: 'inherit',
                      px: { md: 2, lg: 4 },
                    }}
                  >
                    {text}
                  </Button>
                ))}
              </Box>

              {/* Menu User Desktop */}
              <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                <S.WhiteButton
                  sx={{ ml: 1 }}
                  variant='outlined'
                  href={`${
                    process.env.GATSBY_CHINA === 'true'
                      ? process.env.APP_CHINA_URL
                      : process.env.APP_URL
                  }/auth`}
                  rel='noopener'
                >
                  Login
                </S.WhiteButton>
                <Button
                  sx={{ ml: 1 }}
                  variant='contained'
                  href={`${
                    process.env.GATSBY_CHINA === 'true'
                      ? process.env.APP_CHINA_URL
                      : process.env.APP_URL
                  }/signup`}
                  rel='noopener'
                >
                  Join now
                </Button>
              </Box>
            </Toolbar>
          </Container>
        </S.Header>
      </ChangeOnScroll>
      <Box component='nav'>
        <Drawer
          variant='temporary'
          open={toggleDrawer}
          onClose={handleDrawerToggle}
          anchor='top'
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              borderRadius: '0 0 32px 32px',
            },
            boxShadow: 1,
          }}
        >
          <Toolbar />
          {drawer}
        </Drawer>
      </Box>
    </>
  );
};

export default Header;
