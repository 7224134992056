import { createTheme } from '@mui/material/styles';

export let theme = createTheme({
  palette: {
    primary: {
      main: '#32A261',
      light: '#DBF1E4',
      dark: '#2E8954',
      contrastText: '#ffffff',
    },
    action: {
      disabled: '#A7C9BB',
      disabledBackground: '#DBF1E4',
    },
    text: {
      primary: '#353F58',
      main: '#353F58',
    },
    secondary: {
      main: '#353f58',
      light: '#777D8F',
      dark: '#1D2947',
    },
    info: {
      main: '##F9FAFC',
      light: '#D5D7DD',
      dark: '#989DA8',
    },
    background: {
      default: '#ffffff',
      paper: '#F9FAFC',
    },
    indigo: {
      main: '#062A85',
      dark: '#071027',
    },
    silkyGreen: {
      main: '#226E50',
    },
    white: {
      light: '#EDF0F6',
      main: '#ffffff',
      dark: '#ffffff',
      contrastText: '#32A261',
    },
    divider: '#E6E8EF',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1280,
    },
  },
  typography: {
    fontFamily: `"Lato", sans-serif`,
    fontSize: 16,
    h1: {
      fontFamily: `"Fraunces", serif`,
      fontWeight: 700,
      fontSize: '32px',
      lineHeight: '40px',
    },
    h2: {
      fontFamily: `"Fraunces", serif`,
      fontWeight: 700,
      fontSize: '26px',
      lineHeight: '32px',
    },
    h3: {
      fontFamily: `"Fraunces", serif`,
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '22px',
    },
    h4: {
      fontFamily: `"Fraunces", serif`,
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '20px',
    },
    h5: {
      fontFamily: `"Fraunces", serif`,
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '19px',
    },
    h6: {
      fontFamily: `"Fraunces", serif`,
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '17px',
    },
    subhead: {
      fontFamily: 'Lato',
      fontWeight: 700,
      letterSpacing: '0.2em',
      textTransform: 'uppercase',
      fontSize: '12px',
      lineHeight: '15px',
    },
    buttonLarge: {
      fontFamily: 'Lato',
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '22px',
    },
    button: {
      fontFamily: 'Lato',
      fontWeight: 700,
      textTransform: 'none',
      fontSize: '16px',
      lineHeight: '19px',
    },
    buttonSmall: {
      fontFamily: 'Lato',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '17px',
    },
    lead: {
      fontFamily: 'Lato',
      fontSize: '16px',
      lineHeight: '19px',
    },
    body1: {
      fontFamily: 'Lato',
      fontSize: '16px',
      lineHeight: '19px',
    },
    body1Bold: {
      fontFamily: 'Lato',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '19px',
    },
    body2: {
      fontFamily: 'Lato',
      fontSize: '14px',
      lineHeight: '17px',
    },
    body2Bold: {
      fontFamily: 'Lato',
      fontWeight: 700,

      fontSize: '14px',
      lineHeight: '17px',
    },
    body3: {
      fontFamily: 'Lato',
      fontSize: '12px',
      lineHeight: '14px',
    },
    body3Bold: {
      fontFamily: 'Lato',
      fontWeight: 700,

      fontSize: '12px',
      lineHeight: '14px',
    },
    input: {
      fontFamily: 'Lato',
      fontSize: '16px',
      lineHeight: '19px',
    },
    label: {
      fontFamily: 'Lato',
      fontSize: '14px',
      lineHeight: '17px',
    },
  },

  shadows: [
    'none',
    '0px 1px 2px rgba(171, 190, 209, 0.6)',
    '0px 2px 4px rgba(171, 190, 209, 0.6)',
    '0px 3px 6px rgba(171, 190, 209, 0.4)',
    '0px 4px 8px rgba(171, 190, 209, 0.4)',
    '0px 5px 10px rgba(171, 190, 209, 0.3)',
    '0px 6px 12px rgba(171, 190, 209, 0.3)',
    '0px 7px 14px rgba(171, 190, 209, 0.4)',
    '0px 8px 16px rgba(171, 190, 209, 0.4)',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  textShadows: [
    0,
    '0px 1px 2px rgba(0, 0, 0, 0.3)',
    '0px 2px 4px rgba(0, 0, 0, 0.3)',
    '0px 3px 6px rgba(0, 0, 0, 0.3)',
  ],
  zIndex: {
    appBar: 1250,
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          padding: '12px 32px',
          borderRadius: '50px',
          '&:hover': {
            backgroundColor: '#2E8954',
            borderColor: '#2E8954',
            color: '#ffffff',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.19)',
          },
          '&.Mui-disabled': {
            color: '#A7C9BB',
          },
          '&.MuiButton-contained.Mui-disabled': {
            backgroundColor: '#DBF1E4',
            border: 'none',
          },
          '&.MuiButton-outlined.Mui-disabled': {
            borderColor: '#DBF1E4',
          },
          '&.MuiButton-sizeSmall': {
            fontSize: '14px',
            padding: '8px 24px',
          },
          '&.MuiButton-sizeLarge': {
            fontSize: '18px',
            padding: '16px 32px',
          },
          '&.MuiButton-outlinedWhite': {
            borderColor: '#FFFFFF',
            '&:hover': {
              borderColor: '#2E8954',
            },
          },
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        disableGutters: true,
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
        },
      },
    },
    MuiChip: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        root: {
          borderRadius: '8px',
          fontWeight: 700,
          fontSize: '14px',
          lineHeight: '16px',
          '& .MuiChip-icon': {
            marginLeft: '12px',
          },
        },
      },
    },
    MuiFab: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        root: {
          minHeight: 0,
          '&.MuiFab-sizeSmall': {
            fontSize: '16px',
            height: '32px',
            width: '32px',
          },
          '&.MuiFab-sizeMedium': {
            fontSize: '18px',
            height: '48px',
            width: '48px',
          },
          '&.MuiFab-sizeLarge': {
            fontSize: '24px',
            height: '64px',
            width: '64px',
          },
          '&.MuiFab-extended': {
            width: 'auto',
            paddingRight: '20px',
            paddingLeft: '20px',
          },
        },
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        fontSize: 'inherit',
      },
    },
    MuiListItemButton: {
      defaultProps: {
        disableTouchRipple: true,
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        root: {
          '& .MuiAvatarGroup-avatar': {
            border: 'none',
          },
        },
      },
    },
  },
});

theme = createTheme(theme, {
  typography: {
    h1: {
      [theme.breakpoints.up('sm')]: {
        fontSize: '64px',
        lineHeight: '78px',
      },
    },
    h2: {
      [theme.breakpoints.up('sm')]: {
        fontSize: '42px',
        lineHeight: '52px',
      },
    },
    h3: {
      [theme.breakpoints.up('sm')]: {
        fontSize: '28px',
        lineHeight: '35px',
      },
    },
    h4: {
      [theme.breakpoints.up('sm')]: {
        fontSize: '20px',
        lineHeight: '25px',
      },
    },
    h5: {
      [theme.breakpoints.up('sm')]: {
        fontSize: '18px',
        lineHeight: '22px',
      },
    },
    h6: {
      [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
        lineHeight: '19px',
      },
    },
    subhead: {
      [theme.breakpoints.up('sm')]: {
        fontSize: '14px',
        lineHeight: '17px',
      },
    },
    lead: {
      [theme.breakpoints.up('sm')]: {
        fontSize: '20px',
        lineHeight: '24px',
      },
    },
    body1: {
      [theme.breakpoints.up('sm')]: {
        fontSize: '18px',
        lineHeight: '22px',
      },
    },
    body1Bold: {
      [theme.breakpoints.up('sm')]: {
        fontSize: '18px',
        lineHeight: '22px',
      },
    },
    body2: {
      [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
        lineHeight: '19px',
      },
    },
    body2Bold: {
      [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
        lineHeight: '19px',
      },
    },
    body3: {
      [theme.breakpoints.up('sm')]: {
        fontSize: '14px',
        lineHeight: '17px',
      },
    },
    body3Bold: {
      [theme.breakpoints.up('sm')]: {
        fontSize: '14px',
        lineHeight: '17px',
      },
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          '&.MuiContainer-disableGutters': {
            [theme.breakpoints.down('xl')]: {
              paddingLeft: '16px',
              paddingRight: '16px',
            },
          },
        },
      },
    },
  },
});
