import * as React from "react";
import "../../style/components/loading.scss";
import { StaticImage } from "gatsby-plugin-image";

class Loading extends React.Component {
  render() {

    return (
      <div id="loading" className="h-100 w-100 flex-column align-items-center position-absolute">
        <StaticImage 
          src="../../images/favicon/fs-icon-144x144.png" 
          width={80}
          aspectRatio={1/1}
          className="loadingImg" 
          alt="Foursource logo loading"
          placeholder="blurred"/>
      </div>
    );
  }
}

export default Loading;
